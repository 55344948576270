<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Файлы ответа </template>
        <template #content>
          <AbstractTable
            ref="dataTable"
            code="reportResponses"
            display-filter="menu"
            hide-header
          >
            <template #actions="row">
              <Button icon="pi pi-fw pi-download" label="Скачать" @click="downloadReportResponse(row.data.id)"></Button>
            </template>
          </AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";

export default {
  name: "ReportResponses",
  components: {AbstractTable},
  methods: {
    downloadReportResponse(id) {
      window.open('/download/report_response_file/' + id, '_blank')
    },
    refreshTable() {
      this.$refs.dataTable.getData()
    }
  }
}
</script>